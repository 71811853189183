






















































































import ReadOnlyEventComponent from "@/popupcomponents/readonlyeventcomponent/ReadOnlyEventComponent";
export default ReadOnlyEventComponent;
