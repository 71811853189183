import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as Datepicker from 'vuejs-datepicker';
import { IProgramEvent } from '@/Model/programModel';
import programPlanner from '@/store/modules/programPlannerStore'
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from "@/utilities/commonFunctions";
import { ScreenText } from '@/lang/ScreenText';
import { deleteholidayEvent } from './../../services/program/programPlannerService';

@Component({
    components: {
        'datepicker': Datepicker.default
    }
})

export default class ReadOnlyEventComponent extends Vue {
    @Prop()
    plannerEventObj!: IProgramEvent;
    private objScreenText: ScreenText = new ScreenText();
    public toggleButton: boolean = false;

    @Prop()
    toggleEvt: boolean = false;

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    @Watch('toggleEvt')
    toogleCheck() {
        this.toggleButton = this.toggleEvt;
    }

    toggle() {
        this.toggleButton = !this.toggleButton;
    }

    public convertTo24Hour(time:string) {
        time = time.toUpperCase();
        let hours:any = parseInt(time.substr(0, 2));
        if(time.indexOf('AM') != -1 && hours == 12) {
            time = time.replace('12', '0');
        }
        if(time.indexOf('PM')  != -1 && hours < 12) {
            time = time.replace(hours, (hours + 12));
        }
        
        return time;
      
    }

    editEvent() {
        this.toggleButton = false;
        this.$emit(APP_CONST.OPEN_UPDATE_EVENT_SCREEN, this.plannerEventObj)
    } 
    
    editHoliday(){
        this.toggleButton = false;
        this.$emit(APP_CONST.OPEN_UPDATE_HOLIDAY_SCREEN, this.plannerEventObj) 
    }
    DateFormat(data: string) {
        const date = APP_UTILITIES.formatShortDate(data);
        return date;
    }

    back() {
        this.$emit(APP_CONST.BACK_BUTTON)
        this.toggleButton = false;
    }

    deleteEv() {
        if(this.plannerEventObj.holiday) {
            this.deleteHolidayEvent();
        } else {
            this.deleteEvent();
        }
    }

    deleteEvent() {
        const objToDlt = { ...this.plannerEventObj };
        programPlanner.deleteEvent(objToDlt.id).then(() => {
            if (programPlanner.deletedEvent.status === APP_CONST.RESPONSE_200) {
                const ObjPlannerEvent = objToDlt;
                this.toggleButton = false;
                this.$emit(APP_CONST.DELETE_EVENT, { ...ObjPlannerEvent , state: 'deleteEvent' })
                this.plannerEventObj.createdAt = new Date() as any;
            }
        })
    }

    deleteHolidayEvent() {
        const objToDlt = { ...this.plannerEventObj };
        deleteholidayEvent(objToDlt.id).then((response) => {
            if (response.status === APP_CONST.RESPONSE_200) {
                const ObjPlannerEvent = objToDlt;
                this.toggleButton = false;
                this.$emit(APP_CONST.DELETE_EVENT, { ...ObjPlannerEvent , state: 'deleteEvent' })
                this.plannerEventObj.createdAt = new Date() as any;
            }
        })
    }
}

