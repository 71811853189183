




























































































import ReadOnlyTaskComponent from "@/popupcomponents/readonlytaskcomponent/ReadOnlyTaskComponent";
export default ReadOnlyTaskComponent;
