import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from "@/utilities/commonFunctions";

@Component
export default class programPlanFilter extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public otherStateArr = { Active: 0, Done: 0 };
    public filterList: any[] = APP_CONST.FILTER;
    public checkAvailableMonths: string[] = [];
    private getMonths: any = APP_CONST.GET_MONTHS;
    private boolFlag: boolean = false;
    public currentDate = APP_UTILITIES.getCurrentDate();
    public filterOtherStatus: string[] = ['Active'];
    public skippedListCount : number = 0;

    @Prop()
    showFilter!: boolean;


    @Prop()
    filterData!: any;

    @Prop()
    totalCount!: any;

    @Prop()
    resetCurrFilter!: any;
    

   
    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    public convertDateInGMT(date: string) {
        return new Date(date)
    }


    public showSkippedTasks(){
        this.$emit(APP_CONST.SKIPPED_LIST, true);
    }

    beforeMount() {
        this.otherStateFilter();
    }

    @Watch('$route', {deep: true,immediate:true})
    watchRoute() {
        if(this.$route && this.$route.path === '/programplan') {
            this.filterList = [{'month' : '','active' : 'Active','done' :'', 'year': ''}];
            this.filterOtherStatus = ['Active'];
        }
    }

    @Watch('resetCurrFilter', {deep: true,immediate:true})
    resetTaskFilter() {
            this.filterList = [{'month' : '','active' : 'Active','done' :'', 'year': ''}];
            this.filterOtherStatus = ['Active'];
            this.$emit(APP_CONST.FILTER_LIST, this.filterList);
            this.$emit(APP_CONST.SKIPPED_LIST, false);
    }

    

    otherStateFilter() {
        this.otherStateArr.Active = this.totalCount && this.totalCount.totalActiveTaskCount;
        this.otherStateArr.Done = this.totalCount && this.totalCount.doneTaskCount;
    }

    @Watch(APP_CONST.FILTER_DATA, { deep: true })
    updatedFilter() {
        this.updateFilterWatcher();
    }

    updateFilterWatcher(){
        this.skippedListCount = 0
        if (this.filterData && this.filterData.taskPrepared) {
            if (!this.boolFlag) {                
                this.$emit(APP_CONST.FILTER_LIST, this.filterList);
                this.$emit(APP_CONST.SKIPPED_LIST, false);
                this.boolFlag = true;
            }
        }
        
        if(this.filterData && this.filterData.resetEvntFilter){
            this.filterOtherStatus = []
        }

        this.filterData && this.filterData.skippedTaskList.forEach((element:any, index:number) => {
            if(element.status.state === 'Done'){
                this.skippedListCount +=1;
            }
        });
        this.$emit(APP_CONST.SKIPPED_LIST_COUNT, this.skippedListCount);
    }

    @Watch(APP_CONST.COUNT, { deep: true })
    UpdateCount() {
            this.otherStateFilter();
    }

    addFilter(value: any, status: string) {
        const activeIndex = this.filterList.findIndex((el: any) => (el.active === 'Active'))
        const doneIndex = this.filterList.findIndex((el: any) => (el.done === 'Done'))
        if (status === 'month') {
            const index = this.filterList.findIndex((el: any) => ((el.month === value.startMonth) && (el.year === value.year)))
            if (index === -1) {
                const objToAdd = { 'month': value.startMonth, 'active': '', 'done': '', 'year': value.year };
                if (activeIndex >= 0) {
                    objToAdd.active = 'Active';
                }
                if (doneIndex >= 0) {
                    objToAdd.done = 'Done';
                }
                this.filterList.push(objToAdd);
            }
        } else {
            if (activeIndex === -1 && value === 'Active') {
                const objToAdd = { 'month': '', 'active': value, 'done': '', 'year': '' };
                this.filterList.push(objToAdd);
                this.filterOtherStatus.push('Active');
                for (const filterItem in this.filterList) {
                    this.filterList[+filterItem].active = 'Active';
                }
            }
            if (doneIndex === -1 && value === 'Done') {
                const objToAdd = { 'month': '', 'active': '', 'done': value, 'year': '' };
                this.filterList.push(objToAdd);
                this.filterOtherStatus.push('Done');
                for (const filterItem in this.filterList) {
                    this.filterList[+filterItem].done = 'Done';
                }
            }
        }
        this.monthSectionOnly(activeIndex, doneIndex);
        if((this.filterData && this.filterData.resetEvntFilter) && (this.filterData && this.filterData.eventList) && (Object.keys(this.filterData && this.filterData.eventList).length)){
            let eventListLength = Object.keys(this.filterData && this.filterData.eventList).length;
            if(this.filterList.length  === eventListLength){
                this.$emit(APP_CONST.SCROLL_EVENT, true);
            }
        }
    }

    monthSectionOnly(activeIndex: number, doneIndex: number) {
        const activeId = this.filterList.findIndex((el: any) => ((el.active === 'Active') && !el.done && !el.month && !el.year))
        const doneId = this.filterList.findIndex((el: any) => ((el.done === 'Done') && !el.active && !el.month && !el.year))
        const activeDoneId = this.filterList.findIndex((el: any) => ((el.done === 'Done') && (el.active === 'Active') && !el.month && !el.year))
        const monthIndex = this.filterList.findIndex((el: any) => el.month)
        if ((monthIndex >= 0) && (activeId >= 0)) {
            this.filterList.splice(activeIndex, 1)
        }
        if ((monthIndex >= 0) && (doneId >= 0)) {
            this.filterList.splice(doneIndex, 1)
        }
        if ((monthIndex >= 0) && (activeDoneId >= 0)) {
            this.filterList.splice(activeDoneId, 1)
        }
        if ((activeId === -1) && (doneId === -1) && (monthIndex === -1)) {
            this.filterList = [];
        }

        if ((activeDoneId >= 0)) {
            if (activeId >= 0) {
                this.filterList.splice(activeId, 1)
            }
            if (doneId >= 0) {
                this.filterList.splice(doneId, 1)
            }
        }
        this.$emit(APP_CONST.FILTER_LIST, this.filterList);
        this.$emit(APP_CONST.SKIPPED_LIST, false);
    }


    removeFilter(key: string, value: string) {
        const monthIndex = this.filterList.findIndex((el: any) => el.month === value)
        if (this.filterList.length === 1 && !((value === 'Active') || (value === 'Done'))) {
            this.filterList[0].month = ''
            this.filterList[0].year = ''
        } else {
            if (monthIndex !== -1) {
                this.filterList.splice(monthIndex, 1);
            }
        }

        if ((value === 'Active') || (value === 'Done')) {
            const index = this.filterOtherStatus.findIndex((el: any) => (el === value))
            for (const filterItem in this.filterList) {
                this.filterList[+filterItem][value.toLowerCase()] = '';
            }
            this.filterOtherStatus.splice(index, 1);
        }

        const emptyId = this.filterList.findIndex((el: any) => (!el.active && !el.done && !el.month && !el.year))
        if (emptyId >= 0) {
            this.filterList = [];
        }
        this.$emit(APP_CONST.FILTER_LIST, this.filterList);
        this.$emit(APP_CONST.SKIPPED_LIST, false);
    }


    activeClass(value: any) {
        if (!value) {
            return
        }
        const findIndex = this.filterList.findIndex((el: any) => (el.month === value.startMonth && el.year === value.year))
        if (findIndex >= 0) {
            return true;
        } else {
            return false;
        }
    }

    otherActiveClass(value: any) {
        if (!value) {
            return
        }
        const Index = this.filterList.findIndex((el: any) => el[value.toLowerCase()] === value)
        if (Index >= 0) {
            return true;
        } else {
            return false;
        }
    }


    resetFilter() {
        this.filterList = [];
        this.filterOtherStatus = [];
        this.$emit(APP_CONST.FILTER_LIST, this.filterList);
        this.$emit(APP_CONST.SKIPPED_LIST, false);
    }

    closeFilterFn() {
        this.$emit(APP_CONST.OPEN_CLOSE_FILTER, true);
        this.$emit(APP_CONST.SKIPPED_LIST, false);
    }
}